<template>
  <div>
    <hero-bar>
      Cadastro produto
    </hero-bar>

    <section class="section">
      <b-breadcrumb align="is-left">
        <b-breadcrumb-item tag='router-link' to="/">Home</b-breadcrumb-item>
        <b-breadcrumb-item tag='router-link' to="/clientes">Clientes</b-breadcrumb-item>
        <b-breadcrumb-item tag='router-link' :to="`/clientes/${$route.params.id}`">Detalhe cliente</b-breadcrumb-item>
        <b-breadcrumb-item tag='router-link' :to="`/clientes/${$route.params.id}/cadastro-produto`" active>Cadastro produto</b-breadcrumb-item>
      </b-breadcrumb>

      <h3 class="title" v-if="clienteDetalhes">Cliente: {{ clienteDetalhes.Name }}</h3>
      <b-notification v-if="notification.show" :type="notification.type" aria-close-label="Close notification">
        {{ notification.message }}
      </b-notification>

      <div v-if="hasPendingProductsForThisClient">
        <template v-for="(produto, index) in produtosPendentes">
          <form @submit.prevent="cadastroProdutos(produtosPendentes[index])" :key="index">
          <card-product :isCompleted="cadastrados.includes(produtosPendentes[index].Codigo_Produto)" v-model="produtosPendentes[index]" />
          <div class="control" v-if="cadastrados.includes(produtosPendentes[index].Codigo_Produto)">
            <b-button native-type="submit" type="is-primary" :loading="isLoading">Cadastrar Produto</b-button>
          </div>
        <br><br>
        </form>
        </template>
      </div>

      <div v-else>
        <form @submit.prevent="cadastroProdutos(novoProduto, true)">
          <card-product v-model="novoProduto" ref="cardProduto" />
          <div class="control">
            <b-button native-type="submit" type="is-primary" :loading="isLoading">Cadastrar Produto</b-button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import HeroBar from '@/components/HeroBar'
import CardProduct from '@/components/CardProduct'
import { cadastroProdutos, getCliente, productPayload, reponseHandler } from '@/core'
import cliente from '@/mock/clienteDetalhes'

export default {
  name: 'CadastroProduto',
  components: {
    HeroBar,
    CardProduct
  },
  data () {
    return {
      novoProduto: this.objProduto(),
      produtosPendentes: [],
      isLoading: false,
      notification: { show: false },
      clienteDetalhes: null,
      cadastrados: []
    }
  },
  computed: {
    hasPendingProducts () { return this.$store.getters.hasPendingProducts },
    pendingProducts () { return this.$store.getters.pendingProducts },
    hasPendingProductsForThisClient () {
      const res = this.hasPendingProducts && (this.pendingProducts.clientID === this.$route.params.id)
      this.setNotification('info', 'Existem produtos não cadastrados. Cadastre para prosseguir com o processo.')
      return res
    }
  },
  methods: {
    objProduto (idCliente = this.$route.params.id, codProd = null) { return productPayload(idCliente, codProd) },
    cadastroProdutos (bodyProduto, isSingle = false) {
      this.isLoading = true
      cadastroProdutos([bodyProduto])
        .then(res => {
          reponseHandler(res, () => {
            this.status = null
            this.cadastrados.push(bodyProduto.Codigo_Produto)
            this.updatePendingProucts(bodyProduto.Codigo_Produto)
            this.setNotification('success', 'Produtos cadastrado com sucesso!')
            if (isSingle) this.novoProduto = this.objProduto() // limpa campos após cadastro
            this.$refs.cardProduto.resetNCMField()
          })
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log('error', error)
        })
    },
    setNotification (type, message) {
      if (type === 'success') this.notification = { type: 'is-success', message, show: true }
      else if (type === 'error') this.notification = { type: 'is-danger', message, show: true }
    },
    uppercase (key, value) { this.novoProduto[key] = value.toUpperCase() },
    getDetalhesCliente () {
      const id = this.$route.params.id
      getCliente(id)
        .then(res => {
          this.clienteDetalhes = res.data
        })
        .catch(() => {
          this.clienteDetalhes = cliente.data
          this.$buefy.snackbar.open({
            message: 'Não foi possível acessar a API, exibindo dados mockados',
            queue: false
          })
        })
    },
    populatePendingProducts () {
      if (this.hasPendingProductsForThisClient) {
        this.produtosPendentes = this.pendingProducts.products.map(
          codProd => this.objProduto(this.$route.params.id, codProd)
        )
      }
    },
    updatePendingProucts (prodId) {
      this.$store.dispatch('SET_PENDING_PRODUCTS', {
        ...this.pendingProducts,
        products: this.pendingProducts.products.filter(codProd => codProd !== prodId)
      })
    }
  },
  created () {
    this.getDetalhesCliente()
    this.populatePendingProducts()
  }
}
</script>
