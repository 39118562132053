<template>
  <div>
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="{name: 'cliente.cadastroProduto'}" class="button">
        Cadastrar produtos
      </router-link>
      <b-button slot="right"
        label="Importar planilha produtos"
        @click="showUpload = true"
        type="is-info"
        class="ml-2"
      />
    </hero-bar>

    <section class="section pb-0" v-if="showError">
      <b-message title="Erro" type="is-danger" aria-close-label="Close message" @close="closeError">
        <p v-for="(msg, index) in errors" :key="index">{{msg}}</p>
      </b-message>
    </section>

    <section class="section is-main-section">
        <b-breadcrumb align="is-left">
          <b-breadcrumb-item tag='router-link' to="/">Home</b-breadcrumb-item>
          <b-breadcrumb-item tag='router-link' to="/clientes">Clientes</b-breadcrumb-item>
          <b-breadcrumb-item tag='router-link' :to="`/clientes/${$route.params.id}`">Detalhe cliente</b-breadcrumb-item>
          <b-breadcrumb-item tag='router-link' :to="`/clientes/${$route.params.id}/produtos`" active>Produtos</b-breadcrumb-item>
        </b-breadcrumb>

        <upload v-if="showUpload" @onUpdate="insertPlanilha" />

        <lista-produtos :idCliente="$route.params.id" ref="produtos" />
    </section>
    <b-loading :is-full-page="true" v-model="isLoadingPage" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import HeroBar from '@/components/HeroBar'
import ListaProdutos from '@/components/produto/ListaProdutos.vue'
import { deleteCliente, getCliente, formatImportedList, cadastroProdutos, equalsIgnoreOrder, formatCNPJ, notificationError } from '@/core'
import Upload from '@/components/Upload'

export default {
  name: 'DetalheCliente',
  components: {
    HeroBar,
    ListaProdutos,
    Upload
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      clienteDetalhes: {},
      showUpload: false,
      validKeys: ['Descricao_PTBR', 'Descricao_EN', 'Descricao_NF', 'Codigo_Produto', 'NCM', 'Linha_Catalago', 'Unidade_Medida_Comercializada', 'Cod_Unid_Peso', 'Necessita_LI'],
      showError: false,
      errors: [],
      isLoadingPage: false
    }
  },
  computed: {
    heroTitle () {
      if (this.clienteDetalhes && this.nomeCliente) {
        return 'Cliente: ' + this.nomeCliente
      } else {
        return 'Detalhe cliente'
      }
    },
    nomeCliente () {
      return this.clienteDetalhes.Name
    }
  },
  methods: {
    formatDate (v) { return dayjs(v).format('D MMM, YYYY') },
    formatCNPJ (val) { return formatCNPJ(val) },
    getDetalhesCliente () {
      const id = this.$route.params.id
      getCliente(id)
        .then(res => {
          this.clienteDetalhes = res
        })
        .catch(() => {
          this.clienteDetalhes = {}
          notificationError(this.$buefy.notification)
        })
    },
    confirmDelete (client) {
      this.$buefy.dialog.confirm({
        title: 'Deletar cliente',
        message: `Tem certeza que deseja deletar o cliente ${client.name}? Essa ação não pode ser desfeita.`,
        confirmText: 'Deletar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCliente(client)
      })
    },
    deleteCliente (client) {
      this.isLoading = true
      deleteCliente(client.id)
        .then(() => {
          this.$buefy.notification.open({
            duration: 5000,
            message: 'Cliente removido com sucesso.',
            position: 'is-bottom-right',
            type: 'is-success',
            hasIcon: true
          })
          this.$router.push({ name: 'clientes' })
        })
        .catch(() => {
          this.isLoading = false
          notificationError(this.$buefy.notification)
        })
    },
    keysIsValid (rows) { return equalsIgnoreOrder(rows[0], this.validKeys) },
    insertPlanilha (rows) {
      if (this.keysIsValid(rows)) {
        this.isLoadingPage = true
        const list = formatImportedList(rows).map(item => {
          return {
            ...item,
            IDCliente: Number(this.$route.params.id)
          }
        })
        cadastroProdutos(list)
          .then(res => {
            if (res.result === 0) {
              this.$refs.produtos.listaProdutos()
              this.$buefy.notification.open({
                message: 'Produtos cadastrados com sucesso!',
                type: 'is-success',
                duration: 5000
              })
            } else {
              this.setError(res, 'ListaProdutosError')
            }
            this.showUpload = false
            this.isLoadingPage = false
          })
          .catch((error) => {
            console.log('catch', error.response)
            this.showUpload = false
            // notificationError(this.$buefy.notification)
            console.log(error.data)
            this.isLoadingPage = false
          })
      } else {
        this.$buefy.notification.open({
          duration: 7000,
          message: 'Formato de planilha inválido.',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: true
        })
      }
    },
    setError (res, key) {
      this.showError = true
      this.errors = res.response.data.data[key].map(item => item.message)
    },
    closeError () {
      this.showError = false
      this.errors = []
    }
  },
  mounted () {
    this.getDetalhesCliente()
  }
}
</script>
