<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <card-component :title="value.Codigo_Produto" icon="ballot" :isCompleted="isCompleted">
    <!-- <card-component :title="prod" icon="ballot" v-for="(prod, index) in produtosPendentes" :key="index"> -->
      <!-- <input type="hidden" :value="insertCod(prod, index)"> -->
    <div v-show="!isCompleted">
      <div class="columns">
        <div class="column is-one-fifth">
          <b-field label="Código produto">
            <b-input
              required
              v-model="value.Codigo_Produto"
              @input="uppercase('Codigo_Produto', value.Codigo_Produto)"
            />
          </b-field>

        </div>
        <div class="column">
          <b-field expanded label="Descrição PTBR">
            <b-input required v-model="value.Descricao_PTBR" />
          </b-field>

        </div>
      </div>

      <div class="columns">
          <div class="column is-half">
            <b-field expanded label="Descrição EN">
              <b-input required v-model="value.Descricao_EN" />
            </b-field>
          </div>
          <div class="column is-half">
            <b-field expanded label="Descrição NF">
              <b-input required v-model="value.Descricao_NF" />
            </b-field>

          </div>
      </div>
      <div class="columns">
          <div class="column">
            <b-field expanded label="Unidade Medida Comercializada">
              <b-input
                required
                :maxlength="2"
                type="number"
                v-model="value.Unidade_Medida_Comercializada"
                @input="number('Unidade_Medida_Comercializada', value.Unidade_Medida_Comercializada)"
              />
            </b-field>

          </div>
          <div class="column">
            <b-field expanded label="Linha Catálago">
              <b-input required v-model="value.Linha_Catalago" />
            </b-field>

          </div>
          <div class="column">
            <b-field expanded label="Descrição Certificado">
              <b-input required v-model="value.Descricao_Certificado" />
            </b-field>

          </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column">
                <b-field expanded label="Necessita LI">
                  <b-select expanded placeholder="Selecione" v-model="value.Necessita_LI">
                      <option
                          v-for="option in necessitaLIList"
                          :value="option.value"
                          :key="option.key">
                          {{ option.key }}
                      </option>
                  </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field expanded label="Cod_Unid_Peso">
                <b-input
                  required
                  type="number"
                  v-model="value.Cod_Unid_Peso"
                  @input="number('Cod_Unid_Peso', value.Cod_Unid_Peso)"
                />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Selecione o cliente">
                <b-autocomplete
                    v-model="inputNCM"
                    :data="NCMlist"
                    placeholder="Digite o NCM..."
                    field="NCM"
                    :loading="isFetchingNCM"
                    @typing="getNCMData"
                    @select="selecionaNCM"
                    required
                >

                  <template slot-scope="props">
                      <div class="media">
                          <div class="media-content">
                              <strong>{{ props.option.NCM }}</strong>
                              <br>
                              <small>
                                  Mod. Cálc.: {{ props.option.MododeCalculo }} / Preço: {{ props.option.Preco }}
                              </small>
                          </div>
                      </div>
                  </template>
                  <template #empty>Nenhum NCM encontrado</template>
                </b-autocomplete>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>

  </card-component>
</template>
<script>
import CardComponent from '@/components/CardComponent'
import debounce from 'lodash/debounce'
import { searchNCM } from '@/core'

export default {
  name: 'CardProduct',
  components: {
    CardComponent
  },
  data () {
    return {
      necessitaLIList: [
        { key: 'Sim', value: 0 },
        { key: 'Não', value: 1 }
      ],
      isFetchingNCM: false,
      inputNCM: null,
      NCMlist: []
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    isCompleted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    // eslint-disable-next-line vue/no-mutating-props
    uppercase (key, value) { this.value[key] = value.toUpperCase() },
    // eslint-disable-next-line vue/no-mutating-props
    number (key, value) { this.value[key] = Number(value) },
    getNCMData: debounce(function (ncm) {
      if (!ncm.length) {
        this.NCMlist = []
        return
      }
      this.isFetchingNCM = true
      searchNCM(ncm, 1, 50)
        .then(res => {
          if (res.result === 0) {
            this.NCMlist = res.dados
          }
          this.isFetchingNCM = false
        })
        .catch(error => {
          this.isFetchingNCM = false
          console.log(error)
          this.NCMlist = []
        })
    }, 250),
    selecionaNCM (ncmObj) {
      // eslint-disable-next-line vue/no-mutating-props
      this.value.NCM = ncmObj.NCM
    },
    resetNCMField () {
      this.inputNCM = null
    }
  }
}
</script>
